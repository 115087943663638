import React from 'react';
import './StatBox.scss';

export interface IStatBoxProps {
  stat: number;
  description: string;
  background?: string;
  color?: string;
}

export const StatBox = (props: IStatBoxProps) => {
  const { stat, description, background, color } = props;
  return (
    <div className="statBox-container" style={{background: background, color: color}}>
      <div className="stat">{stat}</div>
      <div className="description">{description}</div>
    </div>
  );
};
