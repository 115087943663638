import React from 'react'
import { Color } from '../../global/enums'
import './Button.scss'

export interface IButtonProps {
  onClick?: (event: React.MouseEvent) => void
  onDoubleClick?: (event: React.MouseEvent) => void
  type?: 'outline' | 'gradient' | 'fill' | 'icon'
  isActive?: boolean

  // Content
  text?: string
  icon?: JSX.Element | string
  fontSize?: number | string
  tooltip?: string

  // Colors
  backgroundColor?: string
  primaryColor?: string
  secondaryColor?: string
  activeColor?: string
  color?: string

  // Hover style
  hoverStyle?: 'shadow' | 'darken' | 'lighten' | 'gray' | 'none'

  // Label
  hasLabel?: boolean
  label?: string

  // Additional stylization
  padding?: number
  hasBorder?: boolean
  borderRadius?: number | string
  iconPosition?: 'left' | 'right' | 'top' | 'bottom'
  height?: number

  dark?: boolean;
}

export const Button = (props: IButtonProps) => {
  const {
    text,
    icon,
    onClick,
    isActive,
    type,
    backgroundColor,
    color,
    padding,
    borderRadius,
    primaryColor,
    secondaryColor,
    activeColor,
    hoverStyle,
    hasBorder,
    hasLabel,
    label,
    iconPosition,
    fontSize,
    height,
    dark
  } = props

  /**
   * In the event that there is a single click
   * @param e
   */
  const handleClick = (e: React.MouseEvent) => {
    onClick && onClick(e)
  }

  return (
    <div
      className={`button-container ${isActive} ${dark && 'dark'}`}
      onClick={handleClick}
    >
        {text}
    </div>
  )
}