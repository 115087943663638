import './Home.scss';
import * as ai from 'react-icons/ai'
import * as gi from 'react-icons/gi'
import { FaDonate } from 'react-icons/fa'


export const Home = () => {

  return (
    <div className="home-container">
      <div className={`aboutUs-container`}>
        <h1>About Us</h1>
        <body>
          Brown Men's Club Soccer has both a Fall and Spring season: in the Fall we compete in the <a>NIRSA</a> Boston / Providence division between September - November. In the Spring we compete in the <a>NEPL</a> from February - May and the Ivy League Tournament, normally in April.
          <br/>
          <br/>
          During the season we practice 2-3 times a week and play games during the weekends.
          <br/>
          <br/>
          In our 2022-23 we won the Ivy League Championship for the 5th time in a row, finished first in the Boston / Providence division and competed at Regionals in New Jersey, and lastly won the NEPL regular season and made it to the playoff finals. 
        </body>
      </div>
      <div className={`gallery-container`}>
        <img className={`img`} src="./photos/group.jpeg"></img>
      </div>
      <div className={`joinTheTeam-container`}>
        <h1>Join the Team</h1>
        <body>
          We hold tryouts once a year, normally at the beginning of every Fall semester. Feel free to send an email with questions or fill out the Fall 2023 tryout form, after which we will keep you up to date with emails. Look out for us at the Club Fair during Orientation week!
        </body>
        <div className={`box-button`} onClick={() => window.open('https://forms.gle/MkVjqAy464SPhwkS8', '_blank')}>
          <div className={`icon-start`}><gi.GiSoccerKick/></div>
          <div className={`text`}>Fall 2023 Tryout Form</div>
          <div className={`icon-end`}><ai.AiOutlineArrowRight/></div>
        </div>
        <div className={`box-button`} onClick={() => window.open("mailto:brownclubsoccer@gmail.com")}>
          <div className={`icon-start`}><ai.AiTwotoneMail/></div>
          <div className={`text`}>Email the Captains</div>        
          <div className={`icon-end`}><ai.AiOutlineArrowRight/></div>
        </div>
      </div>
      <div className={`supportUs-container`}>
        <h1>Support Us</h1>
        <body>Make sure to select <b>Support Club and Intramural Sports</b> and then select <b>Club Soccer (Men's)</b>.</body>
        <div className={`box-button red`} onClick={() => window.open('https://bbis.advancement.brown.edu/bbphenix/give-now', '_blank')}>
          <div className={`icon-start`}><FaDonate/></div>
          <div className={`text`}>Donate to BMCS</div>
          <div className={`icon-end`}><ai.AiOutlineArrowRight/></div>  
        </div>
      </div>
    </div>
  );
};
