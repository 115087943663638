import { competitionMap, IGame, teamsMap } from '../../data';
import { Team } from '../../global';
import './Game.scss';

export const Game = (props: IGame) => {
  const { teamA, teamB, kickOff, location, scoreA, scoreB, group, title, pens, competition, goals, mapLink, background, color } = props;
  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const home = teamsMap.get(teamA);
  const away = teamsMap.get(teamB);

  const day = kickOff!.getDate();
  const year = kickOff!.getFullYear();
  const month = kickOff!.getMonth();

  const current = new Date();
  const gameEnd = new Date(kickOff!.getTime() + 120 * 60000);
  const isLive: boolean = current > kickOff! && current < gameEnd;
  const gameFinished: boolean = scoreA !== undefined && scoreB !== undefined;
  const showDate = isLive || gameFinished;
  return (
    <div className="game-wrapper" style={{background: background, color: color}}>
    <div className="game-container" style={{height: away?.key === Team.USCGA ? '270px' : undefined}}>
      <div className="info">
        {competition && <div className="competition">{competitionMap.get(competition)}</div>}
        {<div onClick={() => {mapLink && window.open(mapLink, '_blank');}} className={`location ${mapLink !== undefined}`}>{location}</div>}
        {!title && <div className="group">{group}</div>}
        {title && <div className="group">{title}</div>}
        {isLive && <div className="live">LIVE</div>}
        {showDate && <div className="date"> {day + ' ' + monthNames[month] + ' ' + year}</div>}
      </div>
      <div className="score">
        <div className="team">
          {home?.logo_url ? <img className="image" alt={teamA} src={home.logo_url} /> : <img className="image" alt={teamA} src={'placeholder.png'} />}
          {home?.team_short_name}
        </div>
        <div className="vs">
          {gameFinished ? (
            <div className="score-box">
              {pens && <div className="pens">{pens}</div>}
              <div className="score-container">
                <div className="score-num">
                  {scoreA}
                  {home?.key === "brown" && <div className="scorers">{goals && goals.map((goal) => {
                    return <div className="scorer">{goal.toString()}</div>
                  })}</div>}
                </div>-<div className="score-num">
                  {scoreB}
                  {away?.key === "brown" && <div className="scorers">{goals && goals.map((goal) => {
                    return <div className="scorer">{goal.toString()}</div>
                  })}</div>}
                </div>
              </div>
            </div>
          ) : (
            <div className="time">
              <div className="date">{day + ' ' + monthNames[month] + ' ' + year}</div>
              {'KO ' + kickOff!.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}
            </div>
          )}
        </div>
        <div className="team">
          {away?.logo_url ? <img className="image" alt={teamB} src={away.logo_url} /> : <img className="image" alt={teamB} src={'placeholder.png'} />}
          {away?.team_short_name}
        </div>
      </div>
    </div>
    </div>
  );
};
