import { Popper } from "@mui/material";
import { AiOutlineArrowRight } from "react-icons/ai";
import { GiSoccerKick } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
import { IGame } from "../../data";
import { ContentType, Team } from "../../global";
import "./Recents.scss";

export interface IRecentsProps {
  games: IGame[];
}

export const Recents = (props: IRecentsProps) => {
  const recents: JSX.Element[] = [];
  let navigate = useNavigate();
  const sorted = props.games.sort((a, b) => {
    // if results return closest first
    return b.kickOff!.getTime() - a.kickOff!.getTime();
  });

  sorted.forEach((game, ind) => {
    console.log(game);
    const current = new Date();
    const gameEnd = new Date(game.kickOff!.getTime() + 120 * 60000);
    let result: "w" | "l" | "d" = "w";

    if (game.teamA === Team.Brown || game.teamB === Team.Brown) {
      if (game.scoreA && game.scoreB && game.teamA === Team.Brown) {
        if (game.scoreA === game.scoreB) result = "d";
        else if (game.scoreA > game.scoreB) result = "w";
        else result = "l";
      } else if (game.scoreA && game.scoreB && game.teamB === Team.Brown) {
        if (game.scoreA === game.scoreB) result = "d";
        else if (game.scoreB > game.scoreA) result = "w";
        else result = "l";
      }
      if (current > gameEnd)
        recents.push(
          <>
            <div className={`recent ${result}`}>{result.toUpperCase()}</div>
            <Popper open={true}>
              <div>HELLO WORLD!</div>
            </Popper>
          </>
        );
    }
  });

  const future = (
    <div
      className={`future`}
      onClick={() => navigate("/" + ContentType.Fixtures)}
    >
      <div className={`icon-start`}>
        <GiSoccerKick />
      </div>
      <body style={{ marginBottom: 2 }}>
        Next: 14th October vs <b>Alumni</b>
      </body>
      <div className={`icon-end`}>
        <AiOutlineArrowRight />
      </div>
    </div>
  );

  return (
    <div className="recents-container">
      {/* {recents.slice(0, 5)} */}
      {future}
    </div>
  );
};
