import { Group, Team, Location, Competition } from "../global/enums";
import { IPlayer } from "./players";

export interface IGame {
  teamA: Team;
  teamB: Team | string;
  kickOff: Date;
  location: Location | string;
  scoreA?: number;
  scoreB?: number;
  group?: Group;
  pens?: string;
  title?: string;
  goals?: string[];
  assists?: string[];
  competition?: Competition;
  mapLink?: string;
  background?: string;
  color?: string;
}

export const hardCodedGames: IGame[] = [
  {
    teamA: Team.Cornell,
    teamB: Team.Brown,
    kickOff: new Date("2022-04-23T11:00:00"),
    location: Location.MK,
    scoreA: 2,
    scoreB: 3,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Penn,
    teamB: Team.Brown,
    kickOff: new Date("2022-04-23T14:00:00"),
    location: Location.MK,
    scoreA: 0,
    scoreB: 2,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Dartmouth,
    teamB: Team.Cornell,
    kickOff: new Date("2022-04-23T15:30:00"),
    location: Location.MK,
    scoreA: 3,
    scoreB: 1,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Dartmouth,
    teamB: Team.Penn,
    kickOff: new Date("2022-04-23T18:30:00"),
    location: Location.MK,
    scoreA: 3,
    scoreB: 0,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Dartmouth,
    kickOff: new Date("2022-04-24T08:00:00"),
    location: Location.MK,
    scoreA: 0,
    scoreB: 0,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Penn,
    teamB: Team.Cornell,
    kickOff: new Date("2022-04-24T11:00:00"),
    location: Location.MK,
    scoreA: 1,
    scoreB: 0,
    group: Group.A,
    competition: Competition.I22,
  },
  {
    teamA: Team.Harvard,
    teamB: Team.Yale,
    kickOff: new Date("2022-04-23T09:30:00"),
    location: Location.MK,
    scoreA: 2,
    scoreB: 1,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Columbia,
    teamB: Team.Yale,
    kickOff: new Date("2022-04-23T12:30:00"),
    location: Location.MK,
    scoreA: 1,
    scoreB: 3,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Princeton,
    teamB: Team.Harvard,
    kickOff: new Date("2022-04-23T14:00:00"),
    location: Location.BFF,
    scoreA: 0,
    scoreB: 4,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Princeton,
    teamB: Team.Yale,
    kickOff: new Date("2022-04-23T17:00:00"),
    location: Location.MK,
    scoreA: 0,
    scoreB: 0,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Columbia,
    teamB: Team.Harvard,
    kickOff: new Date("2022-04-23T17:00:00"),
    location: Location.MK,
    scoreA: 1,
    scoreB: 2,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Princeton,
    teamB: Team.Columbia,
    kickOff: new Date("2022-04-24T09:30:00"),
    location: Location.MK,
    scoreA: 6,
    scoreB: 0,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Harvard,
    teamB: Team.Brown,
    kickOff: new Date("2022-04-24T13:00:00"),
    location: Location.MK,
    title: "Semi Final 1",
    scoreA: 2,
    scoreB: 2,
    pens: "Brown win 4-2 in penalties",
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Dartmouth,
    teamB: Team.Princeton,
    kickOff: new Date("2022-04-24T14:30:00"),
    location: Location.MK,
    title: "Semi Final 2",
    scoreA: 0,
    scoreB: 0,
    group: Group.B,
    competition: Competition.I22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Dartmouth,
    kickOff: new Date("2022-04-24T17:00:00"),
    location: Location.MK,
    title: "Final",
    scoreA: 1,
    scoreB: 1,
    group: Group.B,
    pens: "Brown win 4-2 in penalties",
    competition: Competition.I22,
  },
  {
    teamA: Team.Stonehill,
    teamB: Team.Brown,
    scoreA: 1,
    scoreB: 4,
    goals: ["Geireann", "Yonas x2", "Jamey"],
    assists: ["Michael", "Geireann"],
    kickOff: new Date("2022-09-11T12:00:00"),
    location: "320 Washington St, North Eaton, MA",
    competition: Competition.D22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Bryant,
    scoreA: 1,
    scoreB: 0,
    goals: ["Adam"],
    assists: ["Jamey"],
    kickOff: new Date("2022-09-16T19:00:00"),
    location: Location.BFF,
    competition: Competition.D22,
  },
  {
    teamA: Team.PC,
    teamB: Team.Brown,
    scoreA: 0,
    scoreB: 4,
    goals: ["Jamey", "Ian", "Daniel", "Zack"],
    assists: ["Adam", "Will", "Jamey"],
    kickOff: new Date("2022-09-18T13:00:00"),
    location: "1 Cunningham Sq, Providence, RI",
    competition: Competition.D22,
  },
  {
    teamA: Team.BC,
    teamB: Team.Brown,
    scoreA: 1,
    scoreB: 0,
    kickOff: new Date("2022-09-24T15:00:00"),
    location: "140 Commonwealth Ave, Chestnut Hill, MA",
    competition: Competition.D22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Babson,
    goals: ["Geireann", "Nico", "Daniel"],
    kickOff: new Date("2022-09-25T16:15:00"),
    scoreA: 3,
    scoreB: 1,
    location: Location.MK,
    competition: Competition.D22,
  },
  {
    teamA: Team.BU,
    teamB: Team.Brown,
    scoreA: 0,
    scoreB: 1,
    goals: ["Geireann"],
    kickOff: new Date("2022-10-01T20:00:00"),
    location: "233 Bay State Road, Boston, MA",
    competition: Competition.D22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.NEU,
    scoreA: 0,
    scoreB: 0,
    kickOff: new Date("2022-10-08T14:00:00"),
    location: Location.MK,
    competition: Competition.D22,
  },
  {
    teamA: Team.Babson,
    teamB: Team.Stonehill,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 4,
    scoreB: 2,
    competition: Competition.D22,
  },
  {
    teamA: Team.Babson,
    teamB: Team.BC,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 0,
    scoreB: 1,
    competition: Competition.D22,
  },
  {
    teamA: Team.BC,
    teamB: Team.Stonehill,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 4,
    scoreB: 0,
    competition: Competition.D22,
  },
  {
    teamA: Team.Bryant,
    teamB: Team.BC,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 2,
    scoreB: 2,
    competition: Competition.D22,
  },
  {
    teamA: Team.Bryant,
    teamB: Team.Babson,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 1,
    scoreB: 2,
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.Bryant,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 2,
    scoreB: 1,
    competition: Competition.D22,
  },
  {
    teamA: Team.BU,
    teamB: Team.Stonehill,
    kickOff: new Date("2022-09-25T20:00:00"),
    location: Location.MK,
    scoreA: 2,
    scoreB: 1,
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.Babson,
    scoreA: 6,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.PC,
    scoreA: 4,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.BU,
    teamB: Team.PC,
    scoreA: 4,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.BU,
    teamB: Team.BC,
    scoreA: 1,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.Stonehill,
    scoreA: 6,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.BC,
    teamB: Team.PC,
    scoreA: 0,
    scoreB: 0,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.Stonehill,
    teamB: Team.PC,
    scoreA: 1,
    scoreB: 6,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.Bryant,
    teamB: Team.BU,
    scoreA: 2,
    scoreB: 0,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.BU,
    scoreA: 1,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.NEU,
    teamB: Team.BC,
    scoreA: 0,
    scoreB: 1,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.PC,
    teamB: Team.Bryant,
    scoreA: 1,
    scoreB: 0,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.Babson,
    teamB: Team.PC,
    scoreA: 2,
    scoreB: 3,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.Stonehill,
    teamB: Team.Bryant,
    scoreA: 2,
    scoreB: 2,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  {
    teamA: Team.Babson,
    teamB: Team.BU,
    scoreA: 0,
    scoreB: 0,
    location: Location.MK,
    kickOff: new Date("2022-09-25T20:00:00"),
    competition: Competition.D22,
  },
  // REGIONALS
  {
    teamA: Team.Princeton,
    teamB: Team.Brown,
    scoreA: 2,
    scoreB: 3,
    goals: ["Alex", "Robbie", "Daven"],
    location: Location.NJ,
    kickOff: new Date("2022-10-22T20:00:00"),
    competition: Competition.R22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.UConn,
    scoreA: 0,
    scoreB: 3,
    location: Location.NJ,
    kickOff: new Date("2022-10-22T20:00:00"),
    competition: Competition.R22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.StonyBrook,
    scoreA: 3,
    scoreB: 3,
    goals: ["Zaul", "Geireann", "Alex"],
    location: Location.NJ,
    kickOff: new Date("2022-10-23T20:00:00"),
    competition: Competition.R22,
  },
  // SPRING SEASON
  {
    teamA: Team.Brown,
    teamB: Team.Bentley,
    scoreA: 3,
    scoreB: 0,
    goals: ["Tucker", "Milo", "Daniel"],
    location: Location.MK,
    kickOff: new Date("2023-03-04T16:00:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Brown,
    teamB: Team.TuftsB,
    scoreA: 1,
    scoreB: 1,
    goals: ["Geireann"],
    assists: ["Tucker"],
    location: Location.MK,
    kickOff: new Date("2023-03-11T13:45:00"),
    competition: Competition.ND22,
  },
  {
    teamA: Team.Brown,
    teamB: Team.USCGA,
    scoreA: 16,
    scoreB: 1,
    goals: [
      "Jamey x3",
      "Tucker x2",
      "Kavon",
      "Dylan",
      "Zaul",
      "Oscar",
      "Daven",
      "Yonas",
      "Ryan",
      "Alex",
      "Daniel",
      "Geireann",
      "Titas",
    ],
    assists: ["Tucker x4"],
    location: Location.MK,
    kickOff: new Date("2023-03-18T16:00:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Merrimack,
    teamB: Team.Brown,
    scoreA: 0,
    scoreB: 5,
    goals: ["Zaul x3", "Yonas", "Jamey"],
    assists: ["Alex x2", "Geireann", "Yonas", "Jamey"],
    location: "Merrimack College, Andover, MA",
    kickOff: new Date("2023-03-19T20:00:00"),
    competition: Competition.NEPL23,
  },
  {
    teamB: Team.Brown,
    teamA: Team.Suffolk,
    scoreA: 0,
    scoreB: 1,
    goals: ["Jamey"],
    assists: ["Geireann"],
    pens: "Game ended after 35 minutes",
    location: "East Boston Memorial Stadium, Boston, MA",
    kickOff: new Date("2023-04-07T17:30:00"),
    competition: Competition.ND22,
  },
  {
    teamA: Team.BU,
    teamB: Team.Brown,
    scoreA: 2,
    scoreB: 2,
    goals: ["Daven", "Tucker"],
    assists: ["Zaul"],
    location: "Nickerson Field, Boston, MA",
    kickOff: new Date("2023-04-09T17:00:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Stonehill,
    scoreA: 3,
    scoreB: 0,
    pens: "WIN BY FORFEIT",
    location: Location.BFF,
    kickOff: new Date("2023-04-16T14:30:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Brown,
    teamB: Team.Bryant,
    scoreA: 3,
    scoreB: 2,
    goals: ["Mehdi", "Daniel", "Teddy"],
    location: Location.MK,
    kickOff: new Date("2023-04-16T16:15:00"),
    competition: Competition.NEPL23,
  },
  // non brown games
  {
    teamA: Team.BabsonB,
    teamB: Team.Bryant,
    scoreA: 3,
    scoreB: 1,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Bates,
    teamB: Team.TuftsA,
    scoreA: 1,
    scoreB: 8,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Bates,
    teamB: Team.TuftsB,
    scoreA: 2,
    scoreB: 4,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Bates,
    teamB: Team.Colby,
    scoreA: 1,
    scoreB: 5,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Bates,
    teamB: Team.Colby,
    scoreA: 4,
    scoreB: 4,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Wesleyan,
    teamB: Team.NewHaven,
    scoreA: 2,
    scoreB: 1,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.BU,
    teamB: Team.Hult,
    scoreA: 6,
    scoreB: 0,
    location: Location.BFF,
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.BU,
    teamB: Team.BC,
    scoreA: 0,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  {
    teamA: Team.Bentley,
    teamB: Team.Stonehill,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // amherst vs umass: 1-3
  {
    teamA: Team.Amherst,
    teamB: Team.UMassW,
    scoreA: 1,
    scoreB: 3,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // babson a vs babson b: 2-3
  {
    teamA: Team.Babson,
    teamB: Team.BabsonB,
    scoreA: 2,
    scoreB: 3,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // bentley vs UML: 1-1
  {
    teamA: Team.Bentley,
    teamB: Team.UMLowell,
    scoreA: 1,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // bryant vs merrimack: 1-1
  {
    teamA: Team.Bryant,
    teamB: Team.Merrimack,
    scoreA: 1,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hartford vs harvard a: 1-6
  {
    teamA: Team.Hartford,
    teamB: Team.Harvard,
    scoreA: 1,
    scoreB: 6,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hartford vs harvard b: 0-5
  {
    teamA: Team.Hartford,
    teamB: Team.HarvardB,
    scoreA: 0,
    scoreB: 5,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // harvard a vs harvard b: 2-1
  {
    teamA: Team.HarvardB,
    teamB: Team.Harvard,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // holy corss vs umass maroon: 1-2
  {
    teamA: Team.HolyCross,
    teamB: Team.UMassM,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hult vs stonehill: 1-2
  {
    teamA: Team.Hult,
    teamB: Team.Stonehill,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // uml vs babson b: 1-0
  {
    teamA: Team.UMLowell,
    teamB: Team.BabsonB,
    scoreA: 1,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // uml vs merrimack: 1-2
  {
    teamA: Team.UMLowell,
    teamB: Team.Merrimack,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // new haven vs uscga: 11-0
  {
    teamA: Team.NewHaven,
    teamB: Team.USCGA,
    scoreA: 11,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // harvard a vs babson: 3-0
  {
    teamA: Team.Harvard,
    teamB: Team.Babson,
    scoreA: 3,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // harvard a vs babson b: 3-0
  {
    teamA: Team.Harvard,
    teamB: Team.BabsonB,
    scoreA: 3,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // bu vs harvard: 4-1
  {
    teamA: Team.BU,
    teamB: Team.Harvard,
    scoreA: 4,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // clark vs bryant: 2-2
  {
    teamA: Team.Clark,
    scoreA: 2,
    teamB: Team.Bryant,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // conn college vs new haven: 4-1
  {
    teamA: Team.ConnCollege,
    teamB: Team.NewHaven,
    scoreA: 4,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // umass white vs holy cross: 0-0
  {
    teamA: Team.UMassW,
    teamB: Team.HolyCross,
    scoreA: 0,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hartford vs uscga: 0-3
  {
    teamA: Team.Hartford,
    teamB: Team.USCGA,
    scoreA: 0,
    scoreB: 3,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // stonehill vs uml: 1-2
  {
    teamA: Team.Stonehill,
    teamB: Team.UMLowell,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // amherst vs tufc a: 1-5
  {
    teamA: Team.Amherst,
    teamB: Team.TuftsA,
    scoreA: 1,
    scoreB: 5,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // amherst vs tufc b: 0-6
  {
    teamA: Team.Amherst,
    teamB: Team.TuftsB,
    scoreA: 0,
    scoreB: 6,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // babson a vs bc: 1-0
  {
    teamA: Team.Babson,
    teamB: Team.BC,
    scoreA: 1,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // clark vs umass white: 0-1
  {
    teamA: Team.Clark,
    teamB: Team.UMassW,
    scoreA: 0,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // holy cross vs merrimack: 3-1
  {
    teamA: Team.HolyCross,
    teamB: Team.Merrimack,
    scoreA: 3,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // tufc a vs williams: 5-0
  {
    teamA: Team.TuftsA,
    teamB: Team.Williams,
    scoreA: 5,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // tufc b vs williams: 4-1
  {
    teamA: Team.TuftsB,
    teamB: Team.Williams,
    scoreA: 4,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // uml vs harvard b: 1-2
  {
    teamA: Team.UMLowell,
    teamB: Team.HarvardB,
    scoreA: 1,
    scoreB: 2,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hult vs harvard b: 0-4
  {
    teamA: Team.Hult,
    teamB: Team.HarvardB,
    scoreA: 1,
    scoreB: 4,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // bowdoin vs bates: 1-0
  {
    teamA: Team.Bowdoin,
    teamB: Team.Bates,
    scoreA: 1,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // umass maroon vs umass white: 1-1
  {
    teamA: Team.UMassM,
    teamB: Team.UMassW,
    scoreA: 1,
    scoreB: 1,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // hult vs tufts b: 0-5
  {
    teamA: Team.Hult,
    teamB: Team.TuftsB,
    scoreA: 0,
    scoreB: 5,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
  // wesleyan vs uscga: 15-0
  {
    teamA: Team.Wesleyan,
    teamB: Team.USCGA,
    scoreA: 15,
    scoreB: 0,
    location: "",
    kickOff: new Date("2023-04-16T15:15:00"),
    competition: Competition.NEPL23,
  },
];

export const getGamesFromSheet = async (): Promise<IGame[]> => {
  const apiKey = "AIzaSyDOdZqsirbYnOYyYcrNwoGRlvj2TIxCVz8";
  const spreadsheetId = "15nhxdubs6piD550zNFXYlozkLsc1QZaKHledEW7Qwps";
  const range = "games!A1:P1000";
  let response = await fetch(
    `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}?key=${apiKey}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  );
  // await response
  const data = await response.json();
  const values = data.values;

  const sheetGames: IGame[] = [];
  values.forEach((val: any, ind: any) => {
    if (ind === 0) {
      return;
    }
    let scoreA: number | undefined = val[2] as number;
    if (val[2] === "" || val[2] === undefined) {
      scoreA = undefined;
    }
    let scoreB: number | undefined = val[4] as number;
    if (val[4] === "" || val[4] === undefined) {
      scoreB = undefined;
    }
    let goals,
      assists = undefined;
    if (val[11]) goals = val[11].split(",");
    if (val[12]) assists = val[12].split(",");
    const date = new Date(val[7] + "T" + val[8]);
    sheetGames.push({
      teamA: val[1],
      teamB: val[3],
      scoreA: scoreA,
      scoreB: scoreB,
      location: val[9],
      kickOff: date,
      goals: goals,
      assists: assists,
      competition: val[0] as Competition,
      title: val[5],
      group: val[6] as Group,
      pens: val[15],
      mapLink: val[10],
    });
  });
  const games = sheetGames.concat(hardCodedGames);
  return games;
};
