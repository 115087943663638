import { useState } from 'react';
import { Color, Competition, Group } from '../../global/enums';
import { GroupTable } from '../../components/groupTable';
import './Standings.scss';
import { Button, MenuPanel } from '../../components';
import { IGame, competitionMap } from '../../data';

export interface IStandingsProps {
  games: IGame[];
  loading: boolean;
}
export const Standings = (props: IStandingsProps) => {
  const {games} = props
  const [activeCompetition, setCurrentCompetition] = useState<Competition>(Competition.D23);

  return (
    <div className="standings-container">
      <MenuPanel>
        <Button dark isActive={activeCompetition === Competition.D23} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.D23)} text={"NIRSA '23"}></Button>
        <Button dark isActive={activeCompetition === Competition.I23} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.I23)} text={"Ivies '23"}></Button>
        <Button dark isActive={activeCompetition === Competition.NEPL23} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.NEPL23)} text={"NEPL '23"}></Button>
        <Button dark isActive={activeCompetition === Competition.D22} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.D22)} text={"NIRSA '22"}></Button>
        <Button dark isActive={activeCompetition === Competition.I22} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.I22)} text={"Ivies '22"}></Button>
      </MenuPanel>
      {activeCompetition === Competition.D23 && (
        <div className="standings-content">
          <GroupTable competition={Competition.D23} games={games}/>
        </div>
      )}
      {activeCompetition === Competition.I23 && (
        <div className="standings-content">
          <GroupTable competition={Competition.I23} group={Group.A} games={games} />
          <GroupTable competition={Competition.I23} group={Group.B} games={games}/>
        </div>
      )}
      {activeCompetition === Competition.I22 && (
        <div className="standings-content">
          <GroupTable competition={Competition.I22} group={Group.A} games={games} />
          <GroupTable competition={Competition.I22} group={Group.B} games={games}/>
        </div>
      )}
      {activeCompetition === Competition.D22 && (
        <div className="standings-content">
          <GroupTable competition={Competition.D22} games={games}/>
        </div>
      )}
      {activeCompetition === Competition.NEPL23 && (
        <div className="standings-content">
          <GroupTable competition={Competition.NEPL23} games={games}/>
        </div>
      )}
    </div>
  );
};
