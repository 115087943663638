export enum Team {
  UConn = "uconn",
  StonyBrook = "stonybrook",
  Brown = "brown",
  Columbia = "columbia",
  Yale = "yale",
  Harvard = "harvard",
  Cornell = "cornell",
  Penn = "penn",
  Princeton = "princeton",
  Dartmouth = "dartmouth",
  BU = "bu",
  BC = "bc",
  Babson = "babson",
  BabsonB = "babsonb",
  Bates = "bates",
  Bowdoin = "bowdoin",
  Colby = "colby",
  ConnCollege = "conn",
  Hartford = "hartford",
  HarvardB = "harvardb",
  HolyCross = "holyCross",
  Hult = "hult",
  UMassM = "umassm",
  UMassW = "umassw",
  UMLowell = "umlowell",
  NewHaven = "newHaven",
  Wesleyan = "wesleyan",
  Williams = "williams",
  NEU = "nu",
  PC = "pc",
  Bryant = "bryant",
  Stonehill = "stonehill",
  Clark = "clark",
  USCGA = "uscga",
  Merrimack = "merrimack",
  Suffolk = "suffolk",
  Tufts = "tufts",
  Bentley = "bentley",
  TuftsA = "tuftsa",
  TuftsB = "tuftsb",
  Amherst = "amherst",
}

export enum Location {
  MK = "Meister-Kavan",
  BFF = "Berylson Family Fields West",
  NJ = "Bordentown, NJ",
}

export enum Group {
  A = "Group A",
  B = "Group B",
}

export enum Color {
  variantRed = "#c12026",
  variantRedDm = "#df1e4d",
  variantYellow = "#ffee3a",
  variantBrown = "#301819",
  backgroundPrimaryDm = "#1b1b27",
  backgroundTint = "rgba(0, 0, 0, 0.2)",
}

export enum Competition {
  // 2021-22
  I22 = "ivies22",
  // 2022-23
  I23 = "ivies23",
  D22 = "bostonprov22",
  ND22 = "nondiv22",
  R22 = "regionals22",
  NEPL23 = "nepl23",
  // 2023-24
  I24 = "ivies24",
  D23 = "div23",
  ND23 = "nondiv23",
  R23 = "regionals23",
  NEPL24 = "nepl24",
}

export enum Season {
  _22_23,
  _23_24,
}

export enum ContentType {
  Home = "home",
  Team = "team",
  Fixtures = "fixtures",
  Standings = "standings",
  Stats = "stats",
  Results = "results",
}
