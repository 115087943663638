import { IPlayer } from '../../data';
import './PlayerCard.scss';
import {GiSoccerKick} from 'react-icons/gi'
import {AiOutlineCalendar} from 'react-icons/ai'
import {IoMdGlobe} from 'react-icons/io'

export const PlayerCard = (props: IPlayer) => {

  const player = props;

  const randomPlayers: string[] = ['adam.jpg'];
  let url: string;
  if (!player.photo_url) url = 'players/' + randomPlayers[0];
  else url = 'players/' + player.photo_url;
  
  return (
    <div className="player-card">
      <img className="player-image" src={url} alt={player.first_name}></img>
      <div className={`info-wrapper`}>
        <div className={`player-info`}>
          <div className="player-name">
            {player.first_name} {player.last_name}
          </div>
          <div className="player-class">
            <AiOutlineCalendar/>
            Class of {player.class_of}
          </div>
          {player.country && <div className="player-hometown">
            <IoMdGlobe/>
            {player.country}
          </div>}
          {player.positions && (
            <div className="player-positions">
              <GiSoccerKick/>
              {player.positions.map(position => {
                return <div className="player-position">{position}</div>;
              })}
            </div>
          )}
        </div>
      </div>
      
    </div>
  );
};
