import { Popup } from 'browndash-components';
import { teamsMap } from '../../../data';
import './TableRow.scss';

export interface IRowProps {
  ind: number;
  team: string;
  data: Map<string, any>;
}
export const TableRow = (props: IRowProps) => {
  const { team, data,ind } = props;
  const teamObj = teamsMap.get(team);
  return (
    <div className="tableRow-container">
      <div className="rank">{ind + 1}</div>
      <img className="icon" alt="img" src={teamObj?.logo_url} />
      <div className="do-l">{teamObj?.team_short_name}</div>
      <div className="do">{data.get('gp')}</div>
      <div>{data.get('wins')}</div>
      <div>{data.get('draws')}</div>
      <div>{data.get('losses')}</div>
      <div className="do">{data.get('gf')}</div>
      <div className="do">{data.get('ga')}</div>
      <div className="do">{data.get('gd')}</div>
      <div>{data.get('points')}</div>
    </div>
  );
};
