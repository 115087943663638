import React, { useState } from "react";
import { Button, MenuPanel, StatBox } from "../../components";
import { IGame, IPlayer, competitionMap, playersMap } from "../../data";
import { Color, Competition, Location, Team } from "../../global";
import "./Stats.scss";
import { backgroundList } from "../../global/globalUtils";

interface IStatProps {
  games: IGame[];
}

export const Stats = (props: IStatProps) => {
  const {
    games = [
      {
        teamA: Team.Brown,
        teamB: Team.BC,
        kickOff: new Date(),
        location: Location.BFF,
        scoreA: 3,
        scoreB: 1,
        goals: ["geireann x2", "jamey"],
      },
    ],
  } = props;
  let matchesPlayed: number = 0;
  let wins: number = 0;
  let losses: number = 0;
  let goals: number = 0;
  let conceded: number = 0;
  let cleanSheets: number = 0;
  let goalscorers = new Map();
  let assists = new Map();

  const [activeYear, setCurrentYear] = useState<string>("23-24");

  games.forEach((game) => {
    const gameFinished: boolean = game.kickOff < new Date();
    if (!gameFinished) return;
    const scoreA = Number(game.scoreA);
    const scoreB = Number(game.scoreB);
    if (
      activeYear === "22-23" &&
      game.competition !== Competition.D22 &&
      game.competition !== Competition.I23 &&
      game.competition !== Competition.ND22 &&
      game.competition !== Competition.NEPL23 &&
      game.competition !== Competition.R22
    )
      return;
    if (
      activeYear === "23-24" &&
      game.competition !== Competition.D23 &&
      game.competition !== Competition.I24 &&
      game.competition !== Competition.ND23 &&
      game.competition !== Competition.NEPL24 &&
      game.competition !== Competition.R23
    )
      return;
    if (game.goals) {
      game.goals.forEach((val) => {
        let goalsScored = Number(val.replace(/^\D+/g, ""));
        if (goalsScored === 0) goalsScored = 1;
        const name = val.split(" ")[0].toLowerCase();
        console.log(val, name, goalsScored);
        let goalsBefore = goalscorers.get(name);
        if (goalsBefore === undefined) goalsBefore = 0;
        goalscorers.set(name, goalsScored + goalsBefore);
      });
    }
    if (game.assists) {
      game.assists.forEach((val) => {
        let assistsInGame = Number(val.replace(/^\D+/g, ""));
        if (assistsInGame === 0) assistsInGame = 1;
        const name = val.split(" ")[0].toLowerCase();
        let assistsBefore = assists.get(name);
        if (assistsBefore === undefined) assistsBefore = 0;
        assists.set(name, assistsInGame + assistsBefore);
      });
    }
    if (game.teamA === Team.Brown) {
      if (scoreA !== undefined && scoreB !== undefined) {
        goals = goals + scoreA;
        conceded = conceded + scoreB;
        matchesPlayed++;
        if (scoreA > scoreB) wins += 1;
        if (scoreB > scoreA) losses += 1;
        if (scoreB === 0) cleanSheets += 1;
      }
    } else if (game.teamB === Team.Brown) {
      if (game.scoreA !== undefined && game.scoreB !== undefined) {
        goals = goals + game.scoreB;
        conceded = conceded + scoreA;
        matchesPlayed++;
        if (scoreB > scoreA) wins += 1;
        if (scoreA > scoreB) losses += 1;
        if (scoreA === 0) cleanSheets += 1;
      }
    }
  });

  let sortedScorers: { player: IPlayer; goals: number }[] = [];
  let sortedAssists: { player: IPlayer; assists: number }[] = [];

  goalscorers.forEach((val, key) => {
    // console.log(key, val)
    const player = playersMap.get(key);
    if (player)
      sortedScorers.push({
        player: player,
        goals: val,
      });
  });

  assists.forEach((val, key) => {
    // console.log(key, val)
    const player = playersMap.get(key);
    if (player)
      sortedAssists.push({
        player: player,
        assists: val,
      });
  });

  const topScorers: JSX.Element[] = [];
  const topAssists: JSX.Element[] = [];

  sortedAssists = sortedAssists.sort((a, b) => {
    return b.assists - a.assists;
  });

  sortedScorers = sortedScorers.sort((a, b) => {
    return b.goals - a.goals;
  });

  return (
    <div className="stats-container">
      <MenuPanel>
        <Button
          dark
          isActive={activeYear === "All"}
          onClick={() => setCurrentYear("All")}
          text={"All Time"}
        ></Button>
        <Button
          dark
          isActive={activeYear === "23-24"}
          onClick={() => setCurrentYear("23-24")}
          text={"2023-24"}
        ></Button>
        <Button
          dark
          isActive={activeYear === "22-23"}
          onClick={() => setCurrentYear("22-23")}
          text={"2022-23"}
        ></Button>
      </MenuPanel>
      {/* <MenuPanel>
        <Button backgroundColor={activeCompetition == Competition.I22 ? Color.variantRed : undefined} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.I22)} text={competitionMap.get(Competition.I22)}></Button>
        <Button backgroundColor={activeCompetition == Competition.I22 ? Color.variantRed : undefined} borderRadius={5} hoverStyle={"darken"} onClick={() => setCurrentCompetition(Competition.I22)} text={competitionMap.get(Competition.I22)}></Button>
      </MenuPanel> */}
      <div className="teamStats-container">
        <StatBox
          stat={matchesPlayed}
          description={"Matches played"}
          background={backgroundList[0].background}
          color={backgroundList[0].color}
        />
        <StatBox
          stat={wins}
          description={"Wins"}
          background={backgroundList[1].background}
          color={backgroundList[1].color}
        />
        <StatBox
          stat={losses}
          description={"Losses"}
          background={backgroundList[2].background}
          color={backgroundList[2].color}
        />
        <StatBox
          stat={goals}
          description={"Goals"}
          background={backgroundList[0].background}
          color={backgroundList[0].color}
        />
        <StatBox
          stat={conceded}
          description={"Goals conceded"}
          background={backgroundList[1].background}
          color={backgroundList[1].color}
        />
        <StatBox
          stat={cleanSheets}
          description={"Clean sheets"}
          background={backgroundList[2].background}
          color={backgroundList[2].color}
        />
      </div>
      <div className="tops">
        <div className="topAssists-container">
          <h1>Top Assisters</h1>
          {sortedAssists.slice(0, 5).map((val, ind) => {
            return (
              <div className={`ranking-item`}>
                <div className={`num`}>{ind + 1}</div>
                <div className={`name`}>
                  {val.player.first_name + " " + val.player.last_name}
                </div>
                <div className={`count`}>{val.assists}</div>
              </div>
            );
          })}
        </div>
        <div className="topScorers-container">
          <h1>Top Scorers</h1>
          {sortedScorers.slice(0, 5).map((val, ind) => {
            return (
              <div className={`ranking-item`}>
                <div className={`num`}>{ind + 1}</div>
                <div className={`name`}>
                  {val.player.first_name + " " + val.player.last_name}
                </div>
                <div className={`count`}>{val.goals}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
